const middleware = {}

middleware['config'] = require('../middleware/config.js')
middleware['config'] = middleware['config'].default || middleware['config']

middleware['routing'] = require('../middleware/routing.js')
middleware['routing'] = middleware['routing'].default || middleware['routing']

middleware['tutorials'] = require('../middleware/tutorials.js')
middleware['tutorials'] = middleware['tutorials'].default || middleware['tutorials']

export default middleware
